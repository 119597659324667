<template>
  <div class="hello">
    <van-button plain type="info" @click="btn(1)">展商导览</van-button>
    <van-button plain type="info" @click="btn(2)">展会</van-button>
    <van-button plain type="info" @click="btn(3)">展区</van-button>
    <van-button plain type="info" @click="btn(4)">图片新闻</van-button>
    <van-button plain type="info" @click="btn(5)">图片新闻列表</van-button>
    <van-button plain type="info" @click="btn(6)">视频新闻</van-button>
    <van-button plain type="info" @click="btn(7)">茶知识</van-button>
    <van-button plain type="info" @click="btn(8)">茶知识详情</van-button>
    <van-button plain type="info" @click="btn(9)">图文详情</van-button>
    <van-button plain type="info" @click="btn(10)">展商详情</van-button>
    <van-button plain type="info" @click="btn(11)">展会预订</van-button>
    <van-button plain type="info" @click="btn(12)">展商筛选</van-button>
    <van-button plain type="info" @click="btn(13)">活动日程</van-button>
    <van-button plain type="info" @click="btn(14)">茶品牌</van-button>
    <van-button plain type="info" @click="btn(15)">云头条详情</van-button>
    
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  methods:{
    btn(e){
      if(e===1){
        this.$router.push({
          name:'Guide'
        })
      }else if(e===2){
        this.$router.push({
          name:'exhibition'
        })
      }else if(e===3){
        this.$router.push({
          name:'areaBition'
        })
      }else if(e===4){
        this.$router.push({
          name:'newsphoto'
        })
      }else if(e===5){
        this.$router.push({
          name:'newsphotoList'
        })
      }else if(e===6){
        this.$router.push({
          name:'VideoNews'
        })
      }else if(e===7){
        this.$router.push({
          name:'knowledge'
        })
      }else if(e===8){
        this.$router.push({
          name:'knowledgeDetails'
        })
      }else if(e===9){
        this.$router.push({
          name:'GraphicDetails'
        })
      }else if(e===10){
        this.$router.push({
          name:'exhibitorsDetails'
        })
      }else if(e===11){
        this.$router.push({
          name:'reserveBook'
        })
      }else if(e===12){
        this.$router.push({
          name:'exhibitorFilter'
        })
      }else if(e===13){
        this.$router.push({
          name:'itinerary'
        })
      }else if(e===14){
        this.$router.push({
          name:'brandOver'
        })
      }else if(e===15){
        this.$router.push({
          name:'headline'
        })
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>
